export var testimonials = [
    {
        name: "Carissa Karner",
        text: "Yoodli helps me convert prospects into paying clients. Clients’ jaws drop when I show them their Yoodli analytics on an intake call",
        subtext: "TEDx coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Carissa_Karner.jpg"
    },
    {
        name: "Craig Valentine",
        text: "Yoodli saved me hundreds of hours by allowing me to easily collaborate with students. I can’t imagine coaching without it",
        subtext: "World Champion of Public Speaking",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Craig_Valentine.jpeg"
    },
    {
        name: "Mike Davis",
        text: "I’m the doctor and Yoodli is the medical report. It allows me to quickly diagnose your speeches and prescribe the best solutions to improve them",
        subtext: "NSA and TEDx coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Mike_Davis.jpg"
    },
    {
        name: "Jason Jones",
        text: "You’ve streamlined my entire workflow as a sales coach. My note taker, recorder, speech coaching assistant, all in one",
        subtext: "Sales Trainer, CEC, PCC",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Jason_Jones.jpeg"
    },
    {
        name: "Robin Treasure",
        text: "I love that I can give clients a recording and transcript of our calls (especially our role plays) so that they can better retain the material that we cover. It helps them save time w/ note taking",
        subtext: "Sales coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Robin_Treasure.jpeg"
    },
    {
        name: "Alan Hoffler",
        text: "Yoodli allows our clients to practice – on their schedule… and allows us to give feedback – on our schedule.  It’s now an integral part of our workshops and coaching packages. - public speaking coach",
        subtext: "Exec comms coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Alan_Hoffler.jpg"
    },
    {
        name: "Lynda Wilner",
        text: "Yoodli is an valuable tool that supplements my clients’ communication coaching and gives them metrics that will help them improve",
        subtext: "SLP and communication trainer",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Lynda_Katz.jpeg"
    },
    {
        name: "Mark Brown",
        text: "Thanks to the automated transcript, analytics, and commenting features, my time to analyze a speech is cut by 75%. I use Yoodli with every coaching client and on every Zoom call!",
        subtext: "CSP and Toastmasters World Champion",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Mark_Brown.jpeg"
    },
    {
        name: "Lizzie Docel",
        text: "The ability to upload a recording, get a transcript, view analytics AND comment with timestamps is a speech coach's dream",
        subtext: "Presentation trainer at Google",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Lizzie_Docel.jpg"
    },
    {
        name: "Eve Wong",
        text: "My clients are amazed when they view their interview performance on Yoodli. The analytics and transcription help them improve much faster!",
        subtext: "Interview coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Eve_Wong.jpg"
    },
    {
        name: "Brian Hodgson",
        text: "I’ve been a speech coach for 20 years. I can finally show clients data on the way they communicate.",
        subtext: "Speech Trainer",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Brian_Hodgson.jpg"
    },
    {
        name: "Diane Windingland",
        text: "I've got a whitelabeled version of Yoodli - it's customized to my coaching needs, helps me wow prospects, and offer a premium coaching service",
        subtext: "Virtual speech coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Diane_Windingland.jpeg"
    },
    {
        name: "Sally Prosser",
        text: "Yoodli is a game changer for the speaking coaching industry. It’s the perfect sidekick to help my clients feel confident in the way they speak. The feedback has been 100% positive!",
        subtext: "Voice and speech coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Sally_Prosser.png"
    },
    {
        name: "David Otey",
        text: "Time savings. My time to critique a speech has gone down by at least 50%",
        subtext: "NSA member",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/David_Otey.jpeg"
    },
    {
        name: "Melanie Hussell",
        text: "My clients love the time stamped feedback! I can now be extremely precise with how they could improve: voice, body, language or slide design. No more writing notes on a google doc and pausing the video midway",
        subtext: "Business comms coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Melanie_Hussell.jpg"
    },
    {
        name: "Dana Yeaton",
        text: "Yoodli is the breakthrough we’ve been waiting for. With the instant transcript, we can finally coach the entire presentation – delivery AND content.",
        subtext: "University Communications professor",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Dana_Yeaton.JPG"
    },
    {
        name: "Terry Brock",
        text: "Yoodli tells my students how fast they're speaking (sometimes way too fast), the kinds of filler words they use (Did I really say THAT???) and other important communication traits with no judgment that could come from human beings.",
        subtext: "CSP",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Terry_Brock.jpeg"
    },
    {
        name: "Catherine Syme",
        text: "Yoodli is a super-useful tool that significantly improves the learning experience for my clients! Since I have been using Yoodli, I have noticed that the clients who use it for practice are much more likely to watch their videos and make more rapid progress.",
        subtext: "Speech anxiety trainer",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Catherine_Syme.jpeg"
    },
    {
        name: "Ryan Dickerson",
        text: "Yoodli has proven to be exceptionally helpful in bringing data into my interview coaching sessions. In addition to speech analytics, Yoodli gracefully transcribes and time stamps these conversations, giving us the opportunity to refine the stories we consistently tell in an interview setting.",
        subtext: "Career coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Ryan_Dickerson.jpg"
    },
    {
        name: "Susie Ashfield",
        text: "Yoodli is the ultimate tool in getting a new client to convert - they love seeing the data and it really helps me showcase what can be achieved in just a few sessions.",
        subtext: "Coach for C-Suite speakers",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Susie_Ashfield.jpeg"
    },
    {
        name: "Alex Merry",
        text: "Using Yoodli’s white label feature, I’ve created MicDrop Analytics which has been a huge selling point for clients!",
        subtext: "Founder of MicDrop Speech Coaching",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Alex_Merry.jpeg"
    },
    {
        name: "Robyn Albers",
        text: "Yoodli is a game changer.  Clients are blown away by the fact that they can access our courses, practice with AI, and then send me speeches for feedback. In the same amount of time, I'm now earning 35% more.  Plus my clients are happier than ever.",
        subtext: "Corporate communications trainer",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Robyn_Albers.jpeg"
    },
    {
        name: "John Maybury",
        text: "It’s a really easy way to give my feedback to students.",
        subtext: "Presentation coach",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/John_Maybury.jpeg"
    },
    {
        name: "Allison Shapira",
        text: "Through our white-labeled version of Yoodli, clients can watch our microlearning videos, practice and receive instant analytics, and then share their speeches with one of our senior coaches for feedback. This is a game-changer for the future of public speaking skills!",
        subtext: "Harvard faculty",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Allison_Shapira.jpeg"
    },
    {
        name: "Mariko Takahashi",
        text: "We love the capabilities Yoodli offers, from the analytics & transcription, to the customization of the white label version. With the Yoodli technology, we’re able to have a fresh, modern take on trainings, while keeping our traditional, core services at the forefront.",
        subtext: "COO, Clarity Media Group",
        photoURL: "https://storage.googleapis.com/yoodli-public/landing-page-testimonials/coach/Mariko_Takahashi.jpeg"
    }
];
